/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import {
  FormGroup,
  FormControl,
  FormControlProps
} from '@devstart/react-bootstrap';
import HidePassword from '../../../assets/icons/hide-password';
import ShowPassword from '../../../assets/icons/show-password';

import './input-password.css';

type InputPasswordProps = typeof FormControlProps & {
  hasError?: boolean;
  messageError?: string;
};

function InputPassword({
  hasError,
  messageError,
  ...rest
}: InputPasswordProps): JSX.Element {
  const [showPassword, setShowPassword] = useState(false);

  const hasErrorStyle = hasError
    ? { color: '#DC3545', fontSize: '12px', fontWeight: '400' }
    : {};

  return (
    <FormGroup className='input-container'>
      <div id='password-container'>
        <FormControl
          className='form-control-input'
          type={showPassword ? 'text' : 'password'}
          {...rest}
        />
        <span
          id='btn-hide-show-password'
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? (
            <ShowPassword className='img-password-1' />
          ) : (
            <HidePassword className='img-password-2' />
          )}
        </span>
      </div>
      <p style={{ ...hasErrorStyle }}>{hasError ? messageError : ''}</p>
    </FormGroup>
  );
}

InputPassword.displayName = 'InputPassword';

export default InputPassword;
