import { put, call, takeLatest } from 'redux-saga/effects';

import { courseSesiSenaiUrl } from '../../../config/env.json';
import { createFlashMessage } from '../components/Flash/redux';
import { FlashMessages } from '../components/Flash/redux/flash-messages';
import { openModal, closeModal } from '../templates/Challenges/redux/actions';
import { putRegisterPaymentUser } from '../utils/ajax';
import { updatePaymentUser } from './actions';

function* updateUserPaymentSaga({ payload }) {
  try {
    const { data } = yield call(putRegisterPaymentUser, payload);
    if (data.success) {
      yield put(openModal('congratsCompleteAccess'));
    } else if (data.error) {
      yield put(createFlashMessage({ type: 'danger', message: data.message }));
    }
  } catch (e) {
    console.log('Erro to register: ', e);
    yield put(
      createFlashMessage({
        type: 'danger',
        message: FlashMessages.UnavailableService
      })
    );
  }
}

function* verifyPaymentForUser({ payload }) {
  try {
    if (payload.type === 'Success') {
      if (payload.nivelAcesso === 'COMPLETO') {
        const dataToUpdate = {
          access: 'complete',
          source: 'pagantes'
        };
        yield put(closeModal('verifyPaidCourse'));
        yield put(updatePaymentUser(dataToUpdate));
      } else {
        yield put(
          createFlashMessage({
            type: 'danger',
            message: FlashMessages.PaymentNotConfirm
          })
        );
        window.open(courseSesiSenaiUrl, '_blank');
      }
    } else {
      yield put(
        createFlashMessage({
          type: 'danger',
          message: FlashMessages.UnavailableService
        })
      );
    }
  } catch (err) {
    console.log(err);
    yield put(
      createFlashMessage({
        type: 'danger',
        message: FlashMessages.UnavailableService
      })
    );
  }
}

export function createPropsUserSaga(types) {
  return [
    takeLatest(types.updatePaymentUser, updateUserPaymentSaga),
    takeLatest(types.verifyPayment, verifyPaymentForUser)
  ];
}
