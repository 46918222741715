import { createTypes, createAsyncTypes } from '../utils/create-types';

export const ns = 'app';

export const actionTypes = createTypes(
  [
    'appMount',
    'hardGoTo',
    'hideCodeAlly',
    'openSignoutModal',
    'closeSignoutModal',
    'onlineStatusChange',
    'serverStatusChange',
    'resetUserData',
    'tryToShowCodeAlly',
    'executeGA',
    'showCodeAlly',
    'submitComplete',
    'updateComplete',
    'updateFailed',
    'updateUserToken',
    'postChargeProcessing',
    'updateAllChallengesInfo',
    'saveCodeRegistration',
    'changeTourStatus',
    'updateCourseCodeInfo',
    'updateUserRegistrationStatus',
    ...createAsyncTypes('fetchUser'),
    ...createAsyncTypes('postCharge'),
    ...createAsyncTypes('fetchProfileForUser'),
    ...createAsyncTypes('acceptTerms'),
    ...createAsyncTypes('showCert'),
    ...createAsyncTypes('reportUser'),
    ...createAsyncTypes('deleteUserToken'),
    ...createAsyncTypes('saveChallenge'),
    ...createAsyncTypes('signInLocal'),
    ...createAsyncTypes('signUpLocal'),
    ...createAsyncTypes('forgotPassword'),
    ...createAsyncTypes('recoveryPassword'),
    ...createAsyncTypes('verificationEmail'),
    ...createAsyncTypes('submitSubscription'),
    ...createAsyncTypes('verifyPayment'),
    ...createAsyncTypes('updatePaymentUser'),
    ...createAsyncTypes('updateAccessUtm'),
    ...createAsyncTypes('fetchCourseCodeInfo'),
    ...createAsyncTypes('checkUserRegistration'),
    ...createAsyncTypes('submitUserCertificate')
  ],
  ns
);
