/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import {
  FormGroup,
  FormControl,
  FormControlProps
} from '@devstart/react-bootstrap';
import InputMask from 'react-input-mask';

import './input.css';

type InputProps = typeof FormControlProps & {
  icon?: React.ReactNode;
  hasError?: boolean;
  messageError?: string;
  hasMask?: boolean;
  maskValue?: string;
};

function Input({
  hasError,
  messageError,
  hasMask,
  maskValue,
  ...rest
}: InputProps): JSX.Element {
  const hasErrorStyle = hasError
    ? { color: '#DC3545', fontSize: '12px', fontWeight: '400' }
    : {};

  return hasMask ? (
    <div className='input-container form-group'>
      <InputMask
        className='form-control form-control-input'
        mask={maskValue}
        {...rest}
      />
      <p style={{ ...hasErrorStyle }}>{hasError ? messageError : ''}</p>
    </div>
  ) : (
    <FormGroup className='input-container'>
      <FormControl className='form-control-input' {...rest} />
      <p style={{ ...hasErrorStyle }}>{hasError ? messageError : ''}</p>
    </FormGroup>
  );
}

Input.displayName = 'Input';

export default Input;
