import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Loader } from '../../components/helpers';
import {
  userFetchStateSelector,
  isSignedInSelector,
  userSelector
} from '../../redux/selectors';
import createRedirect from '../create-redirect';
import { User } from '../../redux/prop-types';
import { fetchCourseCodeInfo } from '../../redux/actions';
import { AccessLevel } from '../../utils/enums/access-levels';

import './prism.css';
import './prism-night.css';
import 'react-reflex/styles.css';
import './learn.css';

type FetchState = {
  pending: boolean;
  complete: boolean;
  errored: boolean;
};

const mapStateToProps = createSelector(
  userFetchStateSelector,
  isSignedInSelector,
  userSelector,
  (fetchState: FetchState, isSignedIn, user: User) => ({
    fetchState,
    isSignedIn,
    user
  })
);

const mapDispatchToProps = {
  // tryToShowDonationModal
  fetchCourseCodeInfo
};

const RedirectSubscription = createRedirect('/data-complement');

type LearnLayoutProps = {
  readonly isSignedIn?: boolean;
  readonly fetchState: FetchState;
  readonly user: User;
  // tryToShowDonationModal: () => void;
  readonly fetchCourseCodeInfo: (data: { userUF: string }) => void;
  readonly children?: React.ReactNode;
};

const getUserStateAbbreviations = (source: string) => {
  return source.split('_')[1];
};

function LearnLayout({
  isSignedIn,
  fetchState,
  user,
  // tryToShowDonationModal,
  fetchCourseCodeInfo,
  children
}: LearnLayoutProps): JSX.Element {
  useEffect(() => {
    if (
      user.access === AccessLevel.LimitedNacional ||
      user.access === AccessLevel.CompleteNacional
    ) {
      fetchCourseCodeInfo({ userUF: getUserStateAbbreviations(user.source) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      const metaTag = document.querySelector(`meta[name="robots"]`);
      if (metaTag) {
        metaTag.remove();
      }
    };
  }, []);

  if (fetchState.pending && !fetchState.complete) {
    return <Loader fullScreen={true} />;
  }

  if (isSignedIn && !user.subscription) {
    return <RedirectSubscription />;
  }

  return (
    <>
      <Helmet>
        <meta content='noindex' name='robots' />
      </Helmet>
      <main id='learn-app-wrapper'>{children}</main>
      {/* <DonateModal /> */}
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnLayout);
