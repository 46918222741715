'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.checkpointIdList = exports.checkpoints = void 0;

exports.checkpoints = {
  firstCheckpoint: {
    challengeId: '62ac954e43682446ba7f465d',
    certificateName: '1° Matrícula - Introdução à Lógica de Programação',
    superOrder: 0,
    phaseOrder: 3,
    order: 27,
    challengeOrder: 17
  },
  secondCheckpoint: {
    challengeId: '6353431243682426459afa3f',
    certificateName: '2° Matrícula - Lógica de Programação',
    superOrder: 0,
    phaseOrder: 7,
    order: 44,
    challengeOrder: 1
  },
  thirdCheckpoint: {
    challengeId: '63c04ebd436824b964b746b8',
    certificateName: '3° Matrícula - HTML e CSS Básico',
    superOrder: 1,
    phaseOrder: 3,
    order: 92,
    challengeOrder: 1
  },
  fourthCheckpoint: {
    challengeId: '648737b64368243e1be3a0e0',
    certificateName: '4° Matrícula - JavaScript para Web Expert',
    superOrder: 1,
    phaseOrder: 7,
    order: 117,
    challengeOrder: 1
  },
  fifthCheckpoint: {
    challengeId: '66688f344368246b87278ce1',
    certificateName: '5° Matrícula - React Principals e Hooks mais utilizados',
    superOrder: 2,
    phaseOrder: 3,
    order: 174,
    challengeOrder: 1
  },
  sixthCheckpoint: {
    challengeId: '66c3495b4368245549b3f2c2',
    certificateName: '6° Matrícula - React com rotas e interações WEB',
    superOrder: 2,
    phaseOrder: 7,
    order: 198,
    challengeOrder: 1
  }
};

exports.checkpointIdList = Object.values(exports.checkpoints).map(
  checkpoint => checkpoint.challengeId
);

/**
 *
 * @param {import("../client/src/redux/prop-types").Challenge} challenge
 * @returns
 */
exports.checkIsCertificateCheckpoint = challenge => {
  return Object.values(exports.checkpoints).some(checkpoint => {
    return (
      challenge.superOrder === checkpoint.superOrder &&
      challenge.phaseOrder === checkpoint.phaseOrder &&
      challenge.order === checkpoint.order &&
      challenge.challengeOrder === checkpoint.challengeOrder
    );
  });
};

/**
 * Returns the checkpoint matching the provided challengeId
 * @param {string} challengeId
 * @returns {Object|null} Matching checkpoint object or null if not found
 */
exports.getCheckpointById = challengeId => {
  return (
    Object.values(exports.checkpoints).find(
      checkpoint => checkpoint.challengeId === challengeId
    ) || null
  );
};

/**
 * Returns the index of the checkpoint matching the provided challengeId
 * @param {string} challengeId
 * @returns {number} Index of the matching checkpoint or -1 if not found
 */
exports.getCheckpointIndexById = challengeId => {
  return Object.values(exports.checkpoints).findIndex(
    checkpoint => checkpoint.challengeId === challengeId
  );
};
