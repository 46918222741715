import { SuperBlocks } from '../../../config/certification-settings';

const logicaDeProgramacaoBase =
  '/learn/logica-de-programacao/projetos-de-certificacao';
const htmlCssJavascriptBase =
  '/learn/html-css-javascript/projetos-de-certificacao-trilha-2';
const reactBase = '/learn/react/projetos-de-certificacao-trilha-3';

// TODO: generate this automatically in a separate file
// from the md/meta.json files for each cert and projects
const certMap = [
  {
    id: '5a553ca864b52e1d8bceea16',
    title: 'Lógica de Programação',
    certSlug: SuperBlocks.LogicaDeProgramacao,
    flag: 'isLogicaDeProgramacaoCert',
    available: true,
    projects: [
      {
        id: '6353431243682426459afa3e',
        title: '🎓 Projeto de certificação 1 - Pontuação dos atletas',
        link: getLogicaDeProgramacaoPath(
          'projeto-de-certificacao-1-pontuacao-dos-atletas'
        ),
        certSlug: SuperBlocks.LogicaDeProgramacao
      },
      {
        id: '6353431243682426459afa3f',
        title: '🎓 Projeto de certificação 2 – Dados dos atletas',
        link: getLogicaDeProgramacaoPath(
          'projeto-de-certificacao–2-dados-dos-atletas'
        ),
        certSlug: SuperBlocks.LogicaDeProgramacao
      }
    ]
  },
  {
    id: '639ca6754368246afcadb529',
    title: 'HTML, CSS e Javascript',
    certSlug: SuperBlocks.HtmlCssJavascript,
    flag: 'isHtmlCssJavascriptCert',
    available: true,
    projects: [
      {
        id: '648737b64368243e1be3a0df',
        title: '🎓 Projeto de certificação 1 – Agência de Viagem',
        link: getHtmlCssJavascriptPath(
          'projeto-de-certificacao-1-agencia-de-viagem'
        ),
        certSlug: SuperBlocks.HtmlCssJavascript
      },
      {
        id: '648737b64368243e1be3a0e0',
        title: '🎓 Projeto de certificação 2 – Página de Postagem',
        link: getHtmlCssJavascriptPath(
          'projeto-de-certificacao-2-página-de-postagem'
        ),
        certSlug: SuperBlocks.HtmlCssJavascript
      }
    ]
  },
  {
    id: '659d43ee4368242406b487a6',
    title: 'React',
    certSlug: SuperBlocks.React,
    flag: 'isReactCert',
    available: true,
    projects: [
      {
        id: '66c3495b4368245549b3f2c1',
        title: '🎓 Desafio 1 - Contador de Cliques Básico ',
        link: getReactPath('contador-de-cliques-basico-3811'),
        certSlug: SuperBlocks.React
      },
      {
        id: '66c3495b4368245549b3f2c2',
        title:
          '🎓 Desafio 2 - Aplicação de Tarefas com Rotas, Estado, Efeito e Contexto ',
        link: getReactPath(
          'aplicacao-de-tarefas-com-rotas-estado-efeito-e-contexto-3812'
        ),
        certSlug: SuperBlocks.React
      }
    ]
  }
] as const;

function getLogicaDeProgramacaoPath(project: string) {
  return `${logicaDeProgramacaoBase}/${project}`;
}

function getHtmlCssJavascriptPath(project: string) {
  return `${htmlCssJavascriptBase}/${project}`;
}

function getReactPath(project: string) {
  return `${reactBase}/${project}`;
}

const titles = certMap.map(({ title }) => title);
type Title = (typeof titles)[number];
const legacyProjectMap: Partial<Record<Title, unknown>> = {};
const projectMap: Partial<Record<Title, unknown>> = {};

certMap.forEach(cert => {
  if (cert.available) {
    // URLs aren't rendered on the settings page
    projectMap[cert.title] = cert.projects;
  }
});

export { certMap, legacyProjectMap, projectMap };
