import React from 'react';
import { navigate } from 'gatsby-link';
import { useTranslation } from 'react-i18next';
import { createSelector } from 'reselect';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal } from '@devstart/react-bootstrap';
import { TFunction } from 'i18next';
import Button from '../../ui/Button';
import { ButtonTypes } from '../../ui/Button/button-types';
import Close3 from '../../../assets/icons/close3';
import medal from '../../../assets/images/medal.png';
import { courseCodeInfoSelector, userSelector } from '../../../redux/selectors';
import {
  challengeMetaSelector,
  isNacionalCertificateOpenSelector
} from '../../../templates/Challenges/redux/selectors';
import {
  ChallengeMeta,
  CourseCodeDataProps,
  User
} from '../../../redux/prop-types';
import { closeModal } from '../../../templates/Challenges/redux/actions';
import {
  checkUserRegistration,
  submitUserCertificate
} from '../../../redux/actions';
import { AccessLevel } from '../../../utils/enums/access-levels';
import { defaultUrl } from '../../../../../config/env.json';
import {
  getCheckpointById,
  getCheckpointIndexById
} from '../../../../../utils/nacional-utils';
import usePageVisibility from '../../../utils/hooks/use-visibility-change';

import './modal-cta-registration.css';

type ModalCtaRegistrationProps = {
  readonly isOpen: boolean;
  readonly user: User;
  readonly courseCodeInfo: CourseCodeDataProps;
  readonly challengeMeta: ChallengeMeta;
  readonly close: () => void;
  readonly checkUserRegistration: (data: {
    cpf: string;
    courseCode: string;
  }) => void;
  readonly submitUserCertificate: (data: {
    ra: string;
    courseCode: string;
    amountOfExtraCertifications: number;
  }) => void;
};

const mapStateToProps = createSelector(
  userSelector,
  isNacionalCertificateOpenSelector,
  courseCodeInfoSelector,
  challengeMetaSelector,
  (
    user: User,
    isOpen: boolean,
    courseCodeInfo: CourseCodeDataProps,
    challengeMeta: ChallengeMeta
  ) => ({
    user,
    isOpen,
    courseCodeInfo,
    challengeMeta
  })
);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      close: () => closeModal('nacionalCertificate'),
      checkUserRegistration,
      submitUserCertificate
    },
    dispatch
  );

const certificates = [
  'introduction-logic-programming',
  'logic-programming',
  'html-css-basics',
  'javascript-for-web-expert',
  'react-principals-hooks-most-used',
  'react-routes-web-integrations'
];

const ModalSteps: React.FC<{ t: TFunction; concludedIndex: number }> = ({
  t,
  concludedIndex
}) => (
  <ul className='steps'>
    {certificates.map((certificate, index) => (
      <li key={certificate}>
        <div
          className='bullet'
          style={{
            background: index <= concludedIndex ? 'green' : 'white',
            color: index <= concludedIndex ? 'white' : 'black'
          }}
        >
          {index + 1}
        </div>
        <span>{t(`nacional-project.modal.${certificate}`)}</span>
      </li>
    ))}
  </ul>
);

const getModalTextContent = (
  t: TFunction,
  currentCertificateName: string,
  concludedIndex: number,
  challengeMeta: ChallengeMeta,
  close: () => void
): JSX.Element => (
  <div className='left-side'>
    <div className='intro-texts'>
      <span className='unlocked-certificate'>
        {t('nacional-project.modal.unlock-certificate')}
      </span>

      <h1 className='main-title'>
        {t('nacional-project.modal.concluded-certificate', {
          currentCertificateFinish: currentCertificateName
        })}
      </h1>

      <span className='subtitle'>
        {t('nacional-project.modal.keep-going')}
        <br />
      </span>
    </div>

    <ModalSteps t={t} concludedIndex={concludedIndex} />

    <div className='bottom-area'>
      <Button
        buttonType={ButtonTypes.Primary}
        width='440px'
        onClick={() => {
          close();
          void navigate(challengeMeta.nextChallengePath ?? '');
        }}
      >
        {t('nacional-project.modal.next-cert')}
      </Button>
    </div>
  </div>
);

const getLastCertificateModal = (
  t: TFunction,
  currentCertificateName: string,
  concludedIndex: number,
  close: () => void
): JSX.Element => (
  <div className='left-side'>
    <div className='intro-texts'>
      <span className='unlocked-certificate'>
        {t('nacional-project.modal.unlock-certificate')}
      </span>

      <h1 className='main-title'>
        {t('nacional-project.modal.congrats-last-trail')}
      </h1>

      <div className='subtitle'>
        {t('nacional-project.modal.last-trail-subtitle', {
          currentCertificateFinish: currentCertificateName
        })}
        <br />
      </div>
    </div>

    <ModalSteps t={t} concludedIndex={concludedIndex} />

    <div className='bottom-area'>
      <Button buttonType={ButtonTypes.Primary} width='440px' onClick={close}>
        {t('buttons.close')}
      </Button>
    </div>
  </div>
);

const LimitedAccessContent = ({
  t,
  url,
  checkRegistration
}: {
  t: TFunction;
  url: string;
  checkRegistration: () => void;
}) => {
  const handleVisible = () => {
    checkRegistration();
  };

  usePageVisibility(handleVisible);

  const handleClick = () => {
    window.open(url, '_blank');
  };

  return (
    <div className='left-side'>
      <div className='intro-texts'>
        <span className='unlocked-certificate'>
          {t('nacional-project.modal.unlock-certificate')}
        </span>

        <h1 className='main-title'>
          {t('nacional-project.modal.congrats')}
          <br />
          {t('nacional-project.modal.finished-first-trail')}
        </h1>

        <span className='subtitle'>
          {t('nacional-project.modal.to-unlock-certificate')}
        </span>
      </div>

      <ul className='steps'>
        <li>
          <div className='bullet'>1</div>
          <span>{t('nacional-project.modal.step-1')}</span>
        </li>

        <li>
          <div className='bullet'>2</div>
          <span>{t('nacional-project.modal.step-2')}</span>
        </li>

        <li>
          <div className='bullet'>3</div>
          <span>{t('nacional-project.modal.step-3')}</span>
        </li>
      </ul>

      <div className='bottom-area'>
        <Button
          buttonType={ButtonTypes.Primary}
          width='440px'
          onClick={handleClick}
        >
          {t('nacional-project.modal.do-my-registration')}
        </Button>

        <span className='notice-text'>
          {t('nacional-project.modal.notice-text')}
        </span>
      </div>
    </div>
  );
};

const getModalContent = (
  t: TFunction,
  url: string,
  user: User,
  currentCertificateName: string,
  lastCertificateIndex: number,
  challengeMeta: ChallengeMeta,
  close: () => void,
  checkRegistration: () => void
): JSX.Element => {
  if (user.access === AccessLevel.LimitedNacional) {
    return (
      <LimitedAccessContent
        t={t}
        url={url}
        checkRegistration={checkRegistration}
      />
    );
  }

  const currentLastCertificateIndex = 5;

  if (lastCertificateIndex === currentLastCertificateIndex) {
    return getLastCertificateModal(
      t,
      currentCertificateName,
      lastCertificateIndex,
      close
    );
  }

  return getModalTextContent(
    t,
    currentCertificateName,
    lastCertificateIndex,
    challengeMeta,
    close
  );
};

const normalizaName = (challengeName: string) => {
  return challengeName?.split(' - ')[1];
};

const ModalCtaRegistration: React.FC<ModalCtaRegistrationProps> = ({
  isOpen,
  user,
  courseCodeInfo,
  challengeMeta,
  close,
  checkUserRegistration,
  submitUserCertificate
}) => {
  const { t } = useTranslation();

  if (
    ![AccessLevel.CompleteNacional, AccessLevel.LimitedNacional].includes(
      user.access
    )
  ) {
    return null;
  }

  const url = courseCodeInfo?.registrationUrl ?? defaultUrl;

  const extraCertifications = user.extraCertifications || [];

  const currentCheckpoint = getCheckpointById(challengeMeta.id);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const currentCheckpointName = currentCheckpoint?.certificateName ?? '';

  const currentCertificateName = normalizaName(currentCheckpointName);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const currentCheckpointIndex: number = getCheckpointIndexById(
    challengeMeta.id
  );

  const lastExtraCertificationIndex = extraCertifications.length - 1;

  const lastCertificateIndex =
    lastExtraCertificationIndex > currentCheckpointIndex
      ? lastExtraCertificationIndex
      : currentCheckpointIndex;

  const checkRegistration = () => {
    checkUserRegistration({
      courseCode: courseCodeInfo?.courseCodes?.[1] ?? 0,
      cpf: user.cpf
    });
  };

  if (courseCodeInfo && isOpen) {
    const courseCodeValue: string =
      Object.values(courseCodeInfo?.courseCodes)[currentCheckpointIndex] ?? '';

    submitUserCertificate({
      ra:
        user?.userNacionalProgram?.Row?.length > 0
          ? user.userNacionalProgram.Row[0].RA
          : '',
      courseCode: courseCodeValue,
      amountOfExtraCertifications: lastCertificateIndex + 1
    });
  }

  return (
    <Modal
      animation={false}
      dialogClassName='modal-cta-registration'
      keyboard
      onHide={close}
      show={isOpen}
    >
      <Modal.Body>
        <div className='close-icon'>
          <Close3 onClick={close} />
        </div>

        <div className='modal-body-content'>
          {getModalContent(
            t,
            url,
            user,
            currentCertificateName,
            lastCertificateIndex,
            challengeMeta,
            close,
            checkRegistration
          )}

          <div className='right-side'>
            <img src={medal} alt='medal' width={354} height={453} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

ModalCtaRegistration.displayName = 'ModalCtaRegistration';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalCtaRegistration);
