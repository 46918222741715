import React from 'react';
import { Modal } from '@devstart/react-bootstrap';

type HeaderProps = {
  readonly children: string;
  readonly closeButton?: boolean;
};

function Header({ children, closeButton = false }: HeaderProps): JSX.Element {
  return (
    <Modal.Header
      closeButton={closeButton}
      style={{ justifyContent: closeButton ? 'space-between' : 'flex-end' }}
    >
      <Modal.Title>{children}</Modal.Title>
    </Modal.Header>
  );
}

Header.displayName = 'Header';

export default Header;
