import { navigate } from 'gatsby';
import { put, takeEvery, call } from 'redux-saga/effects';

import { createFlashMessage } from '../../../components/Flash/redux';
import { FlashMessages } from '../../../components/Flash/redux/flash-messages';

function* redirectNotAllowedChallenge() {
  try {
    yield put(
      createFlashMessage({
        type: 'danger',
        message: FlashMessages.ChallengeNotAllowed
      })
    );
    yield call(navigate, '/learn');
  } catch (e) {
    console.log(e);
  }
}

export function createNotAllowedChallnge(types) {
  return [takeEvery(types.notAllowedChallenge, redirectNotAllowedChallenge)];
}
