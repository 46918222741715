import React, { ButtonHTMLAttributes } from 'react';
import { Link } from 'gatsby';
import { ButtonTypes } from './button-types';
import './button.css';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  readonly children: React.ReactNode;
  readonly href?: string;
  readonly target?: string;
  readonly width?: string;
  readonly buttonType?: ButtonTypes;
  readonly elementRef?: React.MutableRefObject<null>;
};

const getClassByType = (buttonType?: ButtonTypes): string => {
  switch (buttonType) {
    case ButtonTypes.CTA:
      return 'cta-button-container';
    case ButtonTypes.Primary:
      return 'primary-button-container';
    case ButtonTypes.Secondary:
      return 'secundary-button-container';
    case ButtonTypes.Outline:
      return 'outline-button-container';
    case ButtonTypes.Danger:
      return 'danger-button-container';
    case ButtonTypes.Light:
      return 'light-button-container';
    default:
      return 'cta-button-container';
  }
};

function Button({
  children,
  buttonType,
  elementRef,
  width,
  href,
  target,
  ...rest
}: ButtonProps): JSX.Element {
  const className = `standard-button-style ${getClassByType(buttonType)}`;

  if (href) {
    return (
      <Link
        to={href}
        target={target}
        className={className}
        ref={elementRef}
        style={{ width, ...rest.style }}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      ref={elementRef}
      className={className}
      style={{ width, ...rest.style }}
      {...rest}
    >
      {children}
    </button>
  );
}

Button.displayName = 'Button';

export default Button;
