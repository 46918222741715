import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import Overlay from 'react-overlays/Overlay';
import { challengeTypes } from '../../../../utils/challenge-types';

import { executeChallenge, openModal } from '../redux/actions';
import { challengeMetaSelector } from '../redux/selectors';

import { saveChallenge } from '../../../redux/actions';
import { isSignedInSelector } from '../../../redux/selectors';

import RestartTest from '../../../assets/icons/restart-test';
import RunTest from '../../../assets/icons/run-test';
import HelpFilled from '../../../assets/icons/help-filled';
import Button from '../../../components/ui/Button';
import { ButtonTypes } from '../../../components/ui/Button/button-types';
import { checkIsCertificateCheckpoint } from '../../../../../utils/nacional-utils';
import { Challenge, User } from '../../../redux/prop-types';
import { AccessLevel } from '../../../utils/enums/access-levels';
import HelpMenu from './help-menu';

import './tool-panel.css';

const mapStateToProps = createSelector(
  challengeMetaSelector,
  isSignedInSelector,
  (
    { challengeType }: { challengeId: string; challengeType: number },
    isSignedIn
  ) => ({
    challengeType,
    isSignedIn
  })
);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      executeChallenge,
      openResetModal: () => openModal('reset'),
      openHelpModal: () => openModal('help'),
      saveChallenge
    },
    dispatch
  );

interface ToolPanelProps {
  readonly challengeType: number;
  readonly isSignedIn: boolean;
  readonly isLastChallenge: boolean;
  readonly executeChallenge: (options?: {
    showCompletionModal: boolean;
    isLastChallenge: boolean;
    isNacionalCertificate: boolean;
  }) => void;
  readonly saveChallenge: () => void;
  readonly openResetModal: () => void;
  readonly openHelpModal: () => void;
  readonly challenge: Challenge;
  readonly user: User;
}

function ToolPanel({
  challengeType,
  executeChallenge,
  saveChallenge,
  isSignedIn,
  openResetModal,
  isLastChallenge,
  challenge,
  user
}: ToolPanelProps) {
  const isCheckpointCertificate: boolean =
    checkIsCertificateCheckpoint(challenge);

  const handleRunTests = () => {
    executeChallenge({
      showCompletionModal: true,
      isLastChallenge,
      isNacionalCertificate:
        isCheckpointCertificate &&
        (user.access === AccessLevel.CompleteNacional ||
          user.access === AccessLevel.LimitedNacional)
    });
  };
  const { t } = useTranslation();

  const triggerRef = useRef(null);
  const containerRef = useRef(null);

  const [shown, setShown] = useState(false);

  return (
    <div className='tool-panel-container'>
      <div className='tool-panel-group'>
        <Button
          id='run-code-button'
          buttonType={ButtonTypes.Primary}
          onClick={handleRunTests}
        >
          <RunTest fill='##1A1A1A' />
          {t('buttons.run-test')}
        </Button>

        {isSignedIn &&
          challengeType === challengeTypes.multifileCertProject && (
            <button
              className='btn-secondary-round'
              data-cy='save-code-to-database-btn'
              onClick={saveChallenge}
            >
              {t('buttons.save-code')}
            </button>
          )}

        {challengeType !== challengeTypes.multifileCertProject && (
          <Button
            id='restart-code-button'
            buttonType={ButtonTypes.Outline}
            onClick={openResetModal}
          >
            <RestartTest />
          </Button>
        )}
      </div>

      <Button
        id='help-button'
        elementRef={triggerRef}
        buttonType={ButtonTypes.Outline}
        onClick={() => setShown(!shown)}
        width='max-content'
      >
        <HelpFilled width='32' height='32' />
      </Button>

      <div className='menu-overlay-container' ref={containerRef}>
        <Overlay
          show={shown}
          rootClose
          container={containerRef}
          placement='top-start'
          offset={[10, 10]}
          target={triggerRef}
          onHide={() => setShown(false)}
        >
          {({ props }) => (
            <div {...props} className='menu-overlay'>
              <HelpMenu />
            </div>
          )}
        </Overlay>
      </div>
    </div>
  );
}

ToolPanel.displayName = 'ToolPanel';

export default connect(mapStateToProps, mapDispatchToProps)(ToolPanel);
